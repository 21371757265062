import React from "react";
import { Col } from "react-bootstrap";
//import NewsFeed from "./NewsFeed/NewsFeed";
import ModernFeed from "./ModernFeed/ModernFeed";

const MainColumn = ({ sections }) => {
  return (
    <Col xs={12} sm={12} md={7} lg={8}>
      {sections.map((section, i) => {
        const { title, posts, loadMore, tag } = section;
        //console.log(loadMore)
        return (
          <ModernFeed
            key={title}
            title={title}
            posts={posts}
            loadMore={loadMore}
            tag={tag}
          />
        );
        // return (
        //   <NewsFeed
        //     key={title}
        //     title={title}
        //     posts={posts}
        //     archive={archive}
        //     teal={i % 2}
        //   />
        // );
      })}
    </Col>
  );
};

export default MainColumn;
