import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Heading5 } from "../../../../../common/ntmwd";
import ModernExcerpt from "./ModernExcerpt";
import moment from "moment";
import { Styled } from "./ModernStyled";
import { WMKLink } from "wmk-lib";
import get from "lodash/get";
import { colors } from "../../../../../../vars/palette";

const ModernFeed = ({ posts, title, loadMore, tag }) => {
  const [loadedPosts, setLoadedPosts] = useState(
    loadMore ? posts.slice(0, loadMore) : posts
  );
  const [sliceIndex, setSliceIndex] = useState(loadMore);
  const loadMorePosts = () => {
    const newSlice = sliceIndex + loadMore + 1;
    setSliceIndex(newSlice);
    setLoadedPosts(posts.slice(0, newSlice));
  };
  const isMaxPosts = posts.length < sliceIndex;
  const slug = get(tag, "slug");
  //console.log(posts.length, sliceIndex);
  return (
    <Row className="flex-column" style={{ marginBottom: "2vh" }}>
      <Styled.Card>
        <Heading5
          din
          style={{
            color: colors.hex("primary"),
            paddingBottom: "1vh",
            marginBottom: "2vh",
            borderBottom: `1px solid ${colors.hex("coaccent")}`,
          }}
        >
          <WMKLink to={"/tags/" + slug}>{title}</WMKLink>
        </Heading5>
        <Row>
          {loadedPosts.map((post, i) => {
            //console.log(post)
            const { title, date, tags, slug, feat, excerpt } = post;
            const { node } = feat ? feat : { node: null };
            return (
              <Col key={title + i} xs={12} sm={12} md={i === 0 ? 12 : 6}>
                <ModernExcerpt
                  title={title}
                  date={moment(date)}
                  tags={tags}
                  slug={slug}
                  feat={node}
                  excerpt={excerpt}
                  big={i === 0 ? true : undefined}
                  tag={tag}
                />
              </Col>
            );
          })}
        </Row>
        {loadMore && !isMaxPosts ? (
          <Row>
            <Col
              style={{
                display: "flex",
                flex: "initial",
                justifyContent: "center",
                padding: "2vh 0",
              }}
            >
              <Styled.LoadMoreButton onClick={loadMorePosts}>
                Load More
              </Styled.LoadMoreButton>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Styled.LoadMoreButton>
                <WMKLink to={"/tags/" + slug}>Read More</WMKLink>
              </Styled.LoadMoreButton>
            </Col>
          </Row>
        )}
      </Styled.Card>
    </Row>
  );
};

export default ModernFeed;
