import React from "react";
import { Row, Col } from "react-bootstrap";
import Img from "gatsby-image";
import { Heading4, Heading6 } from "../../../../../common/ntmwd";
import ModernTags from "./ModernTags";
import { Styled } from "./ModernStyled";
import get from "lodash/get";
import DefaultThumb from "./DefaultThumb";

const ModernExcerpt = ({
  title,
  date,
  feat,
  tags,
  slug,
  excerpt,
  big,
  tag,
}) => {
  const localFile = get(feat, "localFile");
  const fixedModernThumb = get(
    feat,
    "localFile.childImageSharp.fixedModernThumb"
  );
  const fixedModernThumbBig = get(
    feat,
    "localFile.childImageSharp.fixedModernThumbBig"
  );

  return (
    <Styled.Wrapper
      className={big ? "news-excerpt" : "news-excerpt small"}
      noGutters
    >
      <Col xs={big ? 12 : 4} sm={big ? 12 : 4} md={big ? 6 : 4}>
        {fixedModernThumb || fixedModernThumbBig ? (
          <Img
            fixed={big ? fixedModernThumbBig : fixedModernThumb}
            alt={localFile.name}
            style={{ maxWidth: "100%" }}
          />
        ) : <DefaultThumb big={big} />}
      </Col>
      <Col xs={!big ? 8 : undefined} className="text">
        <Row className="flex-column">
          <Col>
            <Styled.HeadlineLink to={"/" + slug}>
              {big ? (
                <Heading4 din>{title}</Heading4>
              ) : (
                <Heading6 din>{title}</Heading6>
              )}
            </Styled.HeadlineLink>
          </Col>
          <Col>
            <Row>
              <ModernTags tags={tags} featured={tag} />
              <Styled.Date xs="auto">{date.format("MMMM D, YYYY")}</Styled.Date>
            </Row>
          </Col>
          {big ? (
            <Col>
              <Styled.Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />
            </Col>
          ) : null}
        </Row>
      </Col>
    </Styled.Wrapper>
  );
};

export default ModernExcerpt;
